<template>
  <colored-popup class="green" :closeAction="closePopup">
    <template v-slot:top-content>
      <div class="flex-column align-center">
        <img src="/images/onboarding/promotion-popup-header.svg"/>
        <div class="title">{{ title }}</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="items ri-large-body">
        <trial-explanation-modal-item text="35 ימי ניסיון בחינם ובלי התחייבות"/>
        <trial-explanation-modal-item v-if="isFreeMonthsPromo" :text="`${monthFreeString} במנוי במתנה`"/>
        <trial-explanation-modal-item :text="endOfBenefitText"/>
        <trial-explanation-modal-item text="לא צריך להוסיף אמצעי תשלום עד סוף תקופת הניסיון"/>
        <trial-explanation-modal-item text="בלי התחייבות ואפשר לבטל בכל שלב" />
      </div>
    </template>

  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapGetters, mapState } from 'vuex';
import TrialExplanationModalItem from './TrialExplanationModalItem';

export default {
  name: 'TrialExplanationModal',
  components: {
    TrialExplanationModalItem,
    ColoredPopup: BaseUI.ColoredPopup,
  },
  data() {
    return {
      checkColor: BaseUI.Colors.riseupRed,
    };
  },
  created() {
    Segment.trackUserGot('TrialExplanationShown');
    Segment.trackUserGotToPixel('TrialExplanationShown');
  },
  computed: {
    ...mapGetters('promotions', ['isPercentageDiscountPromo', 'isFreeMonthsPromo']),
    ...mapState('promotions', ['promotion', 'benefitAmount', 'benefitPercentage']),
    endOfBenefitText() {
      return this.isPercentageDiscountPromo
        // eslint-disable-next-line max-len
        ? `בסוף תקופת הניסיון אפשר לבחור אם להמשיך למנוי בתשלום. ${this.formattedMonths} ב-₪${this.priceAfterDiscount} (${this.benefitPercentage}% הנחה) ולאחר מכן, ₪45 לחודש`
        : 'בסוף תקופת הניסיון אפשר לבחור אם להמשיך למנוי בתשלום של ₪45 לחודש';
    },
    formattedMonths() {
      if (this.benefitAmount === 1) {
        return 'חודש';
      }
      if (this.benefitAmount === 2) {
        return 'חודשיים';
      }
      return `${this.benefitAmount} חודשים`;
    },
    monthFreeString() {
      if (this.benefitAmount === 1) {
        return 'חודש נוסף';
      }
      return `${this.formattedMonths} נוספים`;
    },
    priceAfterDiscount() {
      return 45 - Math.round(45 * (this.benefitPercentage / 10)) / 10;
    },
    title() {
      if (this.isPercentageDiscountPromo) {
        return `החודש הראשון עלינו, וגם ${this.benefitPercentage}% הנחה על ${this.monthFreeString}!`;
      }
      if (this.isFreeMonthsPromo) {
        return `הצטרפו ל־RiseUp ותהנו מ${this.benefitAmount === 1 ? 'חודשיים' : `־${this.benefitAmount + 1} חודשים`} במתנה!`;
      }
      return 'חודש התנסות חינם לנרשמים עכשיו!';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closePopup() {
      Segment.trackUserInteraction('TrialExplanationClosed');
      Segment.trackUserInteractionToPixel('TrialExplanationClosed');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.title {
  margin-top: 20px;
  font-size: 24px;
  line-height: 32px;
  color: $riseup_white;
  font-family: SimplerPro;
  font-weight: bold;
}
.items {
  display: flex;
  flex-direction: column;
  row-gap: $ri-spacing-l;
}
</style>

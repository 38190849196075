<template>
  <div :class="{ 'dynamic-height': dynamicHeight, 'banner': true, [colorVariant]: true}" @click="onBannerClicked">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    onBannerClicked: {
      type: Function,
      required: false,
    },
    dynamicHeight: {
      type: Boolean,
      default: false,
    },
    colorVariant: {
      type: String,
      default: 'green',
      validate: value => ['green', 'blue', 'orange'].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';

.banner {
  color: $riseup_beige;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $card-border-radius;
  box-shadow: 0 0 12px #52525210;
  cursor: pointer;

  &.green {
    background-color: $riseup_green;
  }
  &.blue {
    background-color: $riseup_blue;
  }
  &.orange {
    background-color: $riseup_orange;
  }

  &.dynamic-height {
    height: auto;
  }
}
</style>

import { render, staticRenderFns } from "./SignupBanner.vue?vue&type=template&id=4a60d370&scoped=true&"
import script from "./SignupBanner.vue?vue&type=script&lang=js&"
export * from "./SignupBanner.vue?vue&type=script&lang=js&"
import style0 from "./SignupBanner.vue?vue&type=style&index=0&id=4a60d370&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a60d370",
  null
  
)

export default component.exports
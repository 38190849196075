<template>
  <div class="more-info-div">
    <offer-banner v-if="offerId || canJoinHundredDaysProgram" :offerId="offerId" :canJoinHundredDaysProgram="canJoinHundredDaysProgram"/>
    <promotion-banner v-else :promotion="promotion" />
  </div>
</template>

<script>
import OfferBanner from './SignupBanners/offer-banner/OfferBanner.vue';
import PromotionBanner from './SignupBanners/PromotionBanner';

export default {
  name: 'SignupBanner',
  components: {
    OfferBanner,
    PromotionBanner,
  },
  props: {
    promotion: {
      type: String,
      required: false,
    },
    offerId: {
      type: String,
      required: false,
    },
    canJoinHundredDaysProgram: {
      type: Boolean,
      required: false,
    },
  },
};

</script>
<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings';

.more-info-div {
  width: 100%;
  padding: $ri-spacing-ml $ri-spacing-ml 0;
}
</style>

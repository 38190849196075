<template>
  <div class="flex-column align-center">
    <transition appear appear-class="rotate">
      <colored-svg class="star" :color="starColor" svg-src="/images/shapes/eight-pointed-star.svg"/>
    </transition>
    <div class="margin-top-xs">{{ text }}</div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TrialExplanationModalItem',
  components: {
    ColoredSvg: BaseUI.ColoredSvg,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    starColor: {
      type: String,
      default: BaseUI.Colors.riseupYellow,
    },
  },
};
</script>

<style lang="scss" scoped>
.star {
  height: 32px;
  width: 32px;
  // subtle bounce animation
  transition: transform 1.5s cubic-bezier(0.5, 1.65, 0.41, 0.8);

  &.rotate {
    transform: rotate(180deg);
  }
}
</style>

<template>
  <component :is="bannerComponent"/>
</template>

<script>
import DDLogs from '@/DDLogs';
import { SIGNUP_OFFERING_IDS } from '@/store/modules/signup-offerings/oneOff-Offerings';
import HundredDaysProgramBanner from './HundredDaysProgramBanner.vue';
import AudioOfferBanner from './AudioOfferBanner.vue';

export default {
  name: 'OfferBanner',
  props: {
    offerId: {
      type: String,
      required: false,
    },
    canJoinHundredDaysProgram: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    bannerComponent() {
      if (this.offerId === SIGNUP_OFFERING_IDS.AUDIO_COURSE) {
        return AudioOfferBanner;
      }
      if (this.canJoinHundredDaysProgram || this.offerId === SIGNUP_OFFERING_IDS.HUNDRED_DAYS) {
        return HundredDaysProgramBanner;
      }

      DDLogs.log('got unknown program on signup', { offerId: this.offerId });
      return '';
    },
  },
};
</script>

<style scoped lang="scss">

</style>

<template>
  <colored-popup class="orange" :closeAction="closePopup">
    <template v-slot:top-content>
      <div class="flex-column align-center">
        <img class="logo" src="/images/onboarding/audio-offer.png"/>
        <div class="title ri-medium-bold-headline ri-alte">גישה לקורס ״להיות בטוב עם הכסף״ וחודש התנסות חינם לנרשמים עכשיו!</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="items ri-large-body">
        <trial-explanation-modal-item :star-color="starColor" text="35 ימי ניסיון בחינם" />
        <trial-explanation-modal-item :star-color="starColor" text="קורס אודיו בלעדי בשיתוף רייזאפ וליאור פרנקל"/>
        <trial-explanation-modal-item :star-color="starColor" text="בסוף תקופת הניסיון, מינוי בתשלום של 45 ₪ לחודש"/>
        <trial-explanation-modal-item :star-color="starColor" text="אפשר לבטל את המינוי בכל שלב"/>
      </div>
    </template>

  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import TrialExplanationModalItem from '../../TrialExplanationModalItem.vue';

export default {
  name: 'AudioOfferExplanationModal',
  components: {
    TrialExplanationModalItem,
    ColoredPopup: BaseUI.ColoredPopup,
  },
  data() {
    return {
      starColor: BaseUI.Colors.riseupYellow,
    };
  },
  created() {
    Segment.trackUserGot('AudioOfferExplanationModalShown');
    Segment.trackUserGotToPixel('AudioOfferExplanationModalShown');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closePopup() {
      Segment.trackUserInteraction('AudioOfferExplanationModalClosed');
      Segment.trackUserInteractionToPixel('AudioOfferExplanationModalClosed');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.logo {
  width: 70%;
}

.title {
  color: $riseup_white;
}

.items {
  display: flex;
  flex-direction: column;
  row-gap: $ri-spacing-l;
  padding: 0 $ri-spacing-s;
}
</style>

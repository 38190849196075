<template>
  <div class="checkbox-wrapper">
    <div :class="{'checkbox': true, 'checked': checked}" v-on:click="onClick">
      <img :src="require('@/assets/icons/v-white.svg')" class="checkmark" v-if="checked">
    </div>
    <slot name="title"></slot>
  </div>
</template>

<script>

export default {
  name: 'CheckBox',
  props: {
    toggle: {
      type: Function,
      required: true,
    },
    initiallyChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.initiallyChecked,
    };
  },
  methods: {
    onClick() {
      this.toggle();
      this.checked = !this.checked;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;

  .checkbox {
    height: 24px;
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 5px;
    cursor: pointer;

    &.checked {
      background: $riseup_blue;
    }
    &:not(.checked) {
      border: solid 1px $riseup_black;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .checkmark {

      height: 12px;
      width: 16px;
    }
  }
}
</style>

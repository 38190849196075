<template>
    <banner-page :background-position="bannerBackgroundPosition"
                 :img-src="require(`@/assets/${pageHeaderImagePath}`)"
                 :background-color="bannerBackgroundColor">
      <template v-slot:header>
        <div v-if="canJoinHundredDaysProgram" class="hundred-days-logo padding-vertical-m">
          <img :src="require('@/assets/im-on-it/im-on-it-card-header-text.svg')"/>
        </div>
        <div v-else/>
      </template>
      <snack-bar class="expired-promotion" v-if="isPromotionExpired && enterNameStep" variant="warning">
        <template v-slot:primary>תוקף ההטבה הסתיים</template>
      </snack-bar>
      <signup-banner v-if="showBanner"
                     class="margin-bottom-ml"
                     :promotion="eligiblePromotion"
                     :offerId="offerId"
                     :canJoinHundredDaysProgram="canJoinHundredDaysProgram"/>
      <enter-name v-if="enterNameStep" :next-step="nextPage" :signup-text="signupText"/>
      <enter-email v-else-if="enterEmailStep" :next-step="nextPage" :signup-text="signupText"/>
      <enter-phone-number v-else-if="enterPhoneNumberStep" :next-step="nextPage" :signup-text="signupText"/>
      <signup-enter-code v-else :next="onEnterCode" :signup-text="signupText"/>
    </banner-page>
</template>

<script>

import GenerateCodeMixin from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/GenerateCodeMixin';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import BenefitsApi from '@/api/BenefitsApi';
import promotionConsts from '@/constants/promotions';
import BaseUI from '@riseupil/base-ui';
import LoginApi from '@/api/LoginApi';
import router from '@/router';
import ChallengesApi from '@/api/ChallengesApi';
import auth0 from '@/utils/auth0';
import SignupEnterCode from '../signup/steps/SignupEnterCode.vue';
import EnterName from './steps/EnterName';
import EnterEmail from './steps/EnterEmail';
import EnterPhoneNumber from './steps/EnterPhoneNumber';
import SignupBanner from './steps/SignupBanner';

const STEPS = {
  EnterNameStep: 0,
  EnterEmailStep: 1,
  EnterPhoneNumberStep: 2,
  EnterCodeStep: 3,
};

export default {
  name: 'SignupFlowV3',
  mixins: [GenerateCodeMixin],
  components: {
    EnterName,
    EnterEmail,
    EnterPhoneNumber,
    SignupEnterCode,
    SignupBanner,
    SnackBar: BaseUI.SnackBar,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      currentStep: STEPS.EnterNameStep,
      promotionLoaded: false,
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
    };
  },
  props: {
    promoCode: {
      type: String,
      required: false,
    },
    hundredDaysChallenge: {
      type: String,
      required: false,
    },
    offerId: {
      type: String,
      required: false,
    },
  },
  async created() {
    this.setAnswersFromExternalSources(this.$route.query);
    if (this.promoCode) {
      await this.fetchPromotion(this.promoCode);
    }
    this.promotionLoaded = true;
    if (this.promotion && this.isPromotionExpired) {
      Segment.trackUserGot('ExpiredPromotionUsed', {
        promotion: this.promotion,
        promoCode: this.promoCode,
      });
    }
    this.logIsCustomPromotion();
    Segment.trackUserGot('RegistrationEntered', {
      promotion: this.eligiblePromotion,
      hundredDaysChallenge: this.hundredDaysChallenge,
    });
    Segment.trackUserGot('SignUpDetailsEntered_V3_Marketing');
    Segment.trackUserGot('SignUpDetailsEntered_Marketing');
    Segment.trackUserGotToPixel('SignUpDetailsEntered_Split');

    if (this.hundredDaysChallenge) {
      try {
        await this.getHundredDaysInfo(this.hundredDaysChallenge);
        DDLogs.info('has hundred days challenge', {
          challengeId: this.hundredDaysChallenge,
          canJoinHundredDaysProgram: this.canJoinHundredDaysProgram,
          hundredDaysStartDate: this.hundredDaysStartDate,
        });
      } catch (e) {
        DDLogs.error('Failed getting signup hundred days info', {
          error: e,
          challengeId: this.hundredDaysChallenge,
        });
      }
    }
  },
  computed: {
    ...mapState('promotions', ['promotion', 'validatedPromoCode']),
    ...mapGetters('promotions', ['isPromotionEligible']),
    ...mapState('challenges', ['hundredDaysStartDate']),
    ...mapGetters('challenges', ['canJoinHundredDaysProgram']),
    pageHeaderImagePath() {
      return this.canJoinHundredDaysProgram
        ? 'im-on-it/100days-signup-header.svg'
        : 'banners/blue-shapes-with-logo.svg';
    },
    bannerBackgroundPosition() {
      return this.canJoinHundredDaysProgram
        ? BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT
        : BaseUI.LayoutConsts.BACKGROUND_POSITION.TOP_LEFT;
    },
    ctaProps() {
      return {
        buttonProps: {
          title: 'קדימה',
          disabled: this.currentStep === 3,
        },
      };
    },
    enterNameStep() {
      return this.currentStep === STEPS.EnterNameStep;
    },
    enterEmailStep() {
      return this.currentStep === STEPS.EnterEmailStep;
    },
    enterPhoneNumberStep() {
      return this.currentStep === STEPS.EnterPhoneNumberStep;
    },
    showHeader() {
      return this.currentStep !== STEPS.EnterCodeStep;
    },
    showBanner() {
      return this.promotionLoaded && this.showHeader;
    },
    isPromotionExpired() {
      return this.promotion && !this.isPromotionEligible;
    },
    eligiblePromotion() {
      return this.isPromotionEligible ? this.promotion : undefined;
    },
    signupText() {
      return this.promotion && this.isPromotionEligible ? 'למימוש הטבה והרשמה' : 'הרשמה';
    },
  },
  methods: {
    ...mapMutations('signup', ['resetError']),
    ...mapActions('leadQualification', ['setAnswersFromExternalSources']),
    ...mapActions('promotions', ['fetchPromotion']),
    ...mapActions('challenges', ['getHundredDaysInfo']),
    nextPage() {
      if (this.currentStep === 3) {
        return;
      }
      this.currentStep += 1;
    },
    logIsCustomPromotion() {
      if (promotionConsts.PROMOTION_DETAILS[this.promotion]) {
        Segment.trackUserGot('SignUpDetailsV3_CustomPromotion', {
          promotion: this.promotion,
          promoCode: this.promoCode,
        });
      }
    },
    async generateCodeAndProceed(firstName, lastName, phoneNumber, email, enableMarketingEmails) {
      await this.generateCodeForSignup(firstName, lastName, phoneNumber, email, enableMarketingEmails);
      this.currentStep = STEPS.EnterCodeStep;
    },
    async onEnterCode() {
      if (this.promotion && this.isPromotionEligible) {
        await BenefitsApi.createBenefitByPromoCode(this.validatedPromoCode);
      }

      if (this.hundredDaysChallenge && this.canJoinHundredDaysProgram) {
        try {
          await ChallengesApi.addChallengesProduct();
          await ChallengesApi.addParticipantToChallenge(this.hundredDaysChallenge);
          Segment.trackUserGot('Signup_CustomerAddedToChallenge', {
            challengeId: this.hundredDaysChallenge,
            promotion: this.promotion,
            promoCode: this.promoCode,
          });
        } catch (e) {
          DDLogs.error('Failed adding customer to challenge on signup',
            {
              error: e,
              challengeId: this.hundredDaysChallenge,
              promotion: this.promotion,
              promoCode: this.promoCode,
            });
        }
      }
      const is2faRequired = await LoginApi.is2faRequired();
      if (is2faRequired) {
        Segment.trackUserGot('2faRedirectToAuth0Signup');
        DDLogs.log('Customer redirect to Auth0Signup flow');
        const encodedRedirectTo = auth0.getEncodedRedirectTo({ path: '/sr', query: this.$route.query });
        window.location.href = `/api/partial-auth/login-with-auth0/new?redirectTo=${encodedRedirectTo}`;
      } else {
        await router.replace({ path: '/sr', query: this.$route.query });
      }
    },
    goToLogin() {
      window.location.href = '/sr';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/mixins.scss';

.full-height {
  height: 100%;
}

.expired-promotion {
  @include responsive-padding;
  width: 100%;
  font-weight: bold;
}

.hundred-days-logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  img {
    height: 64px;
  }
}
</style>

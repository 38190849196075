<template>
  <sticky-cta-page :cta-props="ctaProps" @click="$emit('click')" class="step-base">
    <div class="step">
      <slot/>
    </div>
    <div v-if="withLoginLink" class="login-link">
      כבר הצטרפת לרייזאפ בעבר? <span class="link" v-on:click="goToHome">הכניסה מכאן</span>
    </div>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import router from '@/router';

export default {
  name: 'StepBase',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    ctaProps: {
      type: Object,
      required: true,
    },
    withLoginLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async goToHome() {
      router.push({
        path: '/sr',
        query: {
          ...this.$route.query,
          returning: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.step-base {
  justify-content: space-between;
}

.step {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  @include responsive-padding;
  color: $riseup_black;
}

.login-link {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  white-space: pre;
  .link {
    color: $riseup_blue;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
}
</style>

<template>
  <colored-popup class="blue" :closeAction="closePopup">
    <template v-slot:top-content>
      <div class="flex-column align-center">
        <img class="logo" src="/images/onboarding/100-days.svg"/>
        <div class="title ri-medium-headline ri-alte">100 ימים ראשונים ברייזאפ בליווי קבוצתי</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="items ri-large-body">
        <trial-explanation-modal-item :star-color="starColor" text="100 ימים בליווי עם קבוצת ווטסאפ ייעודית" />
        <trial-explanation-modal-item :star-color="starColor" text="תזרים חודשי שעושה סדר בהוצאות ובהכנסות"/>
        <trial-explanation-modal-item :star-color="starColor" text="סרטונים, תוכן, מידע, כלים וטיפים שיעזרו לך לקחת את הכסף בידיים"/>
        <a :href="extraInfoLink" target="_blank" class="margin-top-l">לפרטים נוספים על התוכנית</a>
      </div>
    </template>

  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import TrialExplanationModalItem from '../../TrialExplanationModalItem.vue';

export default {
  name: '100DaysExplanationModal',
  components: {
    TrialExplanationModalItem,
    ColoredPopup: BaseUI.ColoredPopup,
  },
  data() {
    return {
      starColor: BaseUI.Colors.riseupDarkBlue,
      extraInfoLink: 'https://intercom.riseup.co.il/he/articles/'
        + '8588413-%D7%A9%D7%90%D7%9C%D7%95%D7%AA-%D7%95%D7%AA%D7%A9%D7%95%D7%91%D7%95%D7%AA-%D7%A2%D7%9C-'
        + '%D7%AA%D7%95%D7%9B%D7%A0%D7%99%D7%AA-%D7%A2%D7%9C-%D7%96%D7%94-%D7%91-100',
    };
  },
  created() {
    Segment.trackUserGot('100DaysExplanationShown');
    Segment.trackUserGotToPixel('100DaysExplanationShown');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closePopup() {
      Segment.trackUserInteraction('100DaysExplanationClosed');
      Segment.trackUserInteractionToPixel('100DaysExplanationClosed');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.logo {
  width: 70%;
}

.title {
  margin-top: 20px;
  color: $riseup_white;
  width: 210px;
}
.items {
  display: flex;
  flex-direction: column;
  row-gap: $ri-spacing-l;
}
</style>

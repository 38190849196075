<template>
  <banner :on-banner-clicked="openMoreInfo" color-variant="green">
    <template v-slot:content>
      <div class="full-width flex-row align-center space-between margin-sm">
        <div class="flex-row align-center">
          <colored-svg svg-src="/images/shapes/rounded-eight-points-star.svg"
                       :color="iconColor"
                       class="banner-icon"/>
          <div class="ri-bold-body margin-horizontal-s">
            <span>100 ימים ברייזאפ בליווי קבוצתי</span>
          </div>
        </div>
        <icon icon-name="arrow_back_ios_new" size="16px" weight="bold"/>
      </div>
    </template>
  </banner>
</template>

<script>
import Banner from '@/base-components/Banner.vue';
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import HundredDaysExplanationModal from './HundredDaysExplanationModal.vue';

export default {
  name: 'HundredDaysProgramBanner',
  components: {
    Icon: BaseUI.Icon,
    ColoredSvg: BaseUI.ColoredSvg,
    Banner,
  },
  data() {
    return {
      iconColor: BaseUI.Colors.riseupLightGreen,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openMoreInfo() {
      this.openModal({
        component: HundredDaysExplanationModal,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-icon {
  width: 22px;
  height: 22px;
}
</style>

<template>
  <step-base :cta-props="ctaProps" @click="nextClicked">
    <div class="ri-body margin-bottom-xxs">{{ signupText }}</div>
    <div class="ri-large-headline ri-alte margin-bottom-xxs">עוד רגע וסיימנו!</div>
    <div class="ri-large-body">אנחנו צריכים את מספר הטלפון שלך, כדי שנוכל לשלוח לך קוד אימות בוואטסאפ.</div>

    <div class="margin-top-l">
      <text-input
        label-text="מספר טלפון נייד"
        :value="phoneNumber"
        :onInput="checkAndSetPhoneNumber"
        inputType="tel"
        autocomplete="mobile tel"
        :error-message="phoneNumberErrorLabel"/>

    </div>
    <check-box :initially-checked="termsAndConditionsAgreed" :toggle="toggleTermsAndConditions">
      <template v-slot:title>
        <div class="ri-label margin-right-sm">
          קראתי ואישרתי את
          <span class="link" v-on:click="openTerms()">תנאי השימוש</span>
          <span class="link" v-on:click="openPrivacy()">ותנאי הפרטיות</span>
        </div>
      </template>
    </check-box>
    <check-box :initially-checked="enableMarketingEmails" :toggle="toggleEnableMarketingEmails">
      <template v-slot:title>
        <div class="ri-label margin-right-sm">אשמח לקבל עדכונים, טיפים וחדשות למייל</div>
      </template>
    </check-box>
  </step-base>
</template>

<script>

import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import GenerateCodeMixin from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/GenerateCodeMixin';
import StepBase from './StepBase';
import CheckBox from './CheckBox';

const ERRORS = {
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  INVALID_EMAIL: 'INVALID_EMAIL',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  MEMBER_ALREADY_EXISTS: 'MEMBER_ALREADY_EXISTS',
};

export default {
  name: 'EnterPhoneNumber',
  mixins: [GenerateCodeMixin],
  components: {
    TextInput: BaseUI.V2Components.TextInput,
    StepBase,
    CheckBox,
  },
  data() {
    return {
      error: null,
      termsAndConditionsAgreed: false,
      enableMarketingEmails: true,
    };
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    signupText: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('SignUp_V3_Phone_Entered_Marketing');
    Segment.trackUserGotToPixel('RegistrationEntered_Split_PhoneNumberEntered');
    this.setEnableMarketingEmails(this.enableMarketingEmails);
  },
  computed: {
    ...mapState('signup', ['phoneNumber', 'serverErrors', 'firstName', 'lastName', 'email']),
    disabled() {
      return _.isEmpty(_.trim(this.phoneNumber)) || !!this.phoneNumberError || !this.termsAndConditionsAgreed;
    },
    phoneNumberError() {
      return this.serverErrors.generateCode;
    },
    phoneNumberErrorLabel() {
      if (this.phoneNumberError === ERRORS.INVALID_PHONE_NUMBER) {
        Segment.trackUserGot('invalidPhoneNumber', { invalidPhoneNumber: this.phoneNumber });
        return 'מספר הטלפון שהזנת לא תקין';
      }
      if (this.phoneNumberError === ERRORS.MEMBER_ALREADY_EXISTS) {
        return 'היי, נראה שכבר הצטרפת בעבר לשירות';
      }
      return null;
    },
    ctaProps() {
      return {
        buttonProps: {
          title: 'קדימה',
          disabled: this.disabled,
        },
      };
    },
  },
  methods: {
    ...mapMutations('signup', ['setPhoneNumber', 'resetError']),
    checkAndSetPhoneNumber(value) {
      this.resetError('generateCode');
      this.setPhoneNumber(value);
    },
    async nextClicked() {
      Segment.trackUserInteraction('SignUp_V3_Phone_NextClicked_Marketing');
      Segment.trackUserGotToPixel('RegistrationEntered_Split_PhoneNumberEntered_NextClicked');
      try {
        await this.generateCodeForSignup(this.firstName, this.lastName, this.phoneNumber, this.email, this.enableMarketingEmails);
        this.nextStep();
      } catch (e) {
        // left empty
      }
    },
    toggleEnableMarketingEmails() {
      this.enableMarketingEmails = !this.enableMarketingEmails;
      this.setEnableMarketingEmails(this.enableMarketingEmails);
    },
    toggleTermsAndConditions() {
      this.termsAndConditionsAgreed = !this.termsAndConditionsAgreed;
    },
    openTerms() {
      Segment.trackUserGotToPixel('OpenTermAndConditions_Clicked');
      window.open('https://www.riseup.co.il/termsofuse', '_blank');
    },
    openPrivacy() {
      Segment.trackUserGotToPixel('OpenPrivacy_Clicked');
      window.open('https://www.riseup.co.il/privacy-policy', '_blank');
    },
  },
};

</script>
<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.link {
  color: $riseup_blue;
  cursor: pointer;
}

.action {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
  color: $riseup_black;
  font-size: 18px;
  line-height: 10px;

  .action-link {
    font-weight: bold;
    color: $riseup_blue;
    cursor: pointer;
    text-decoration: underline;
    margin-right: 5px;
  }
}
</style>

<template>
  <banner :on-banner-clicked="openMoreInfo">
    <template v-slot:content>
      <div class="full-width flex-row align-center space-between margin-sm">
        <div class="flex-row align-center">
          <colored-svg svg-src="/images/shapes/flower.svg" :color="iconColor" class="banner-icon"/>
          <div class="ri-bold-label margin-horizontal-s">
            <span v-if="!promotion">חודש התנסות חינם לנרשמים עכשיו!</span>
            <div v-else-if="isPercentageDiscountPromo">
              <div>החודש הראשון עלינו,</div>
              <div>וגם {{ benefitPercentage }}% הנחה על {{ moreMonths }}!</div>
            </div>
            <span v-else>הצטרפו ל־RiseUp ותהנו מ{{firstMonths}} במתנה!</span>
          </div>
        </div>
        <icon icon-name="arrow_back_ios_new" size="16px" weight="bold"/>
      </div>
    </template>
  </banner>
</template>

<script>

import Segment from '@/Segment';
import BrowserInfo from '@/mixins/BrowserInfo';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import Banner from '@/base-components/Banner';
import TrialExplanationModal from '../TrialExplanationModal';

export default {
  name: 'PromotionBanner',
  mixins: [BrowserInfo],
  components: {
    Icon: BaseUI.Icon,
    ColoredSvg: BaseUI.ColoredSvg,
    Banner,
  },
  props: {
    promotion: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      iconColor: BaseUI.Colors.riseupLightGreen,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openMoreInfo() {
      Segment.trackUserGot('TrialExplanationClicked');
      Segment.trackUserGotToPixel('TrialExplanationClicked');
      this.openModal({
        component: TrialExplanationModal,
      });
    },
  },
  computed: {
    ...mapGetters('promotions', ['isPercentageDiscountPromo']),
    ...mapState('promotions', ['benefitAmount', 'benefitPercentage']),
    moreMonths() {
      if (this.benefitAmount === 1) {
        return 'חודש נוסף';
      }
      if (this.benefitAmount === 2) {
        return 'חודשיים נוספים';
      }
      return `${this.benefitAmount} חודשים נוספים`;
    },
    firstMonths() {
      if (this.benefitAmount < 1) {
        return 'חודש ראשון';
      }
      if (this.benefitAmount === 1) {
        return 'חודשיים ראשונים';
      }
      return `־${this.benefitAmount + 1} חודשים ראשונים`;
    },
  },
};

</script>
<style lang="scss" scoped>
.banner-icon {
  width: 22px;
  height: 22px;
}
</style>
